import { userStore } from "~/store/user";

export default defineNuxtRouteMiddleware(async (to) => {
  const useUserStore = userStore();

  const token = useCookie("acc_token");
  if (!token.value && to?.name?.toString().includes("profile")) {
    abortNavigation();
    return navigateTo(`/?user=unknown`);
  } else if(token.value) {
    const res = await useUserStore.checkAccTokenExp(token.value);
    if (!res) {
      abortNavigation();
      return navigateTo(`/`);
    }
  }
});
